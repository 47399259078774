import React from 'react';
import { ModuleBase, HeadingTag, ResponsiveImage, Button } from '@/components';
import Star from '../assets/star.svg';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';

const FestiveGalleryModule = ({ data }) => {
  return (
    <ModuleBase data={data} className="bg-primary">
      <FadeUpStarter className="container bg-primary relative flex flex-col gap-10 items-center">
        {(data.toggleForTheStarIllustration || data.preHeading || data.heading?.heading) && (
          <div className="flex flex-col gap-6 items-center max-w-[773px]">
            {data.toggleForTheStarIllustration && (
              <FadeUpAnimation>
                <Star className="fill-white" />
              </FadeUpAnimation>
            )}
            {data.preHeading && (
              <FadeUpAnimation>
                <p className="text-white text-tag-m not-italic font-normal leading-16 -tracking-tagsReg">
                  {data.preHeading}
                </p>
              </FadeUpAnimation>
            )}
            {data.heading && (
              <FadeUpAnimation>
                <HeadingTag
                  data={data.heading}
                  className="font-ivy-ora text-white text-title-l not-italic font-normal leading-60 -tracking-normal"
                />
              </FadeUpAnimation>
            )}
          </div>
        )}
        <div className="flex flex-col md:flex-row gap-3 w-full">
          {data.mediaItems.map((mediaItem, index) => (
            <FadeUpAnimation key={index} className="md:w-1/3 aspect-[445/232]">
              <ResponsiveImage image={mediaItem} widths={{ xs: 393, md: 445 }} heights={{ xs: 205, md: 232 }} />
            </FadeUpAnimation>
          ))}
        </div>
        {data.cta && (
          <FadeUpAnimation>
            <Button link={data.cta} theme="light" type="primary" />
          </FadeUpAnimation>
        )}
      </FadeUpStarter>
    </ModuleBase>
  );
};
export default FestiveGalleryModule;
