import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '..';
import classNames from 'classnames';
import Festiveborder from '../../assets/festiveborder.svg';
import Star from '../../assets/star.svg';

const FestiveButton = ({ link, className, children, ...props }) => {
  return (
    <Link link={link} className={classNames('group festive-button h-10 flex items-center', className)} {...props}>
      <div className="w-2.5 flex flex-col overflow-hidden">
        <Festiveborder className="stroke-primary fill-transparent group-hover:fill-primary transition-colors duration-500" />
        <Festiveborder className="stroke-primary fill-transparent group-hover:fill-primary scale-x-[-1] rotate-180 transition-colors duration-500" />
      </div>

      <div className="text-tag-m font-cera text-primary group-hover:text-highlight-sunshineYellow border-y border-y-primary not-italic font-normal leading-[22px] h-full flex justify-center items-center bg-transparent group-hover:bg-primary transition-colors duration-500">
        <Star className="fill-primary group-hover:fill-highlight-sunshineYellow transition-colors duration-500 mr-0.5" />
        {children || link?.text}
      </div>
      <div className="w-3 flex flex-col items-end overflow-hidden">
        <Festiveborder className="stroke-primary fill-transparent group-hover:fill-primary scale-x-[-1] transition-colors duration-500" />
        <Festiveborder className="stroke-primary fill-transparent group-hover:fill-primary scale-y-[-1] scale-x-[-1] transition-colors duration-500" />
      </div>
    </Link>
  );
};

FestiveButton.propTypes = {
  link: PropTypes.object,
};

export default FestiveButton;
